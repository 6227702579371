import React, { HTMLProps } from 'react';
import cn from 'classnames';

import styles from './FlexContainer.module.css';

interface FlexContainerProps extends HTMLProps<HTMLDivElement> {
  columnGap?: number;
  direction?: 'row' | 'column';
  rowGap?: number;
  stretch?: boolean;
}

export const FlexContainer = ({
  children,
  className,
  columnGap = 12,
  direction = 'row',
  rowGap = 6,
  stretch = true,
  ...props
}: FlexContainerProps) => (
  <div
    className={cn(
      styles.container,
      { [styles.column]: direction === 'column', [styles.stretch]: stretch },
      className,
    )}
    style={{ columnGap, rowGap }}
    {...props}
  >
    {children}
  </div>
);
