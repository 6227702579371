import React from 'react';
import cn from 'classnames';

import styles from './ImageBanner.module.css';

interface ImageBannerProps {
  className: string;
  title?: string;
  verticalAlign?: 'top' | 'center' | 'bottom';
}

let globalId = 0;

export const ImageBanner = ({
  className,
  title,
  verticalAlign = 'center',
}: ImageBannerProps) => {
  const titleId = `image-banner-${globalId++}`;

  return (
    <div className={cn(styles.image, styles[verticalAlign], className)} role="img">
      {title && (
        <div className={styles.title_wrapper}>
          <h1 id={titleId}>{title}</h1>
        </div>
      )}
    </div>
  );
};
