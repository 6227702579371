import React, { PropsWithChildren } from 'react';

import { useMediaQuery } from '../hooks';

type MediaQueryContextType = {
  isLandscape?: boolean;
  isLargeScreen?: boolean;
  isPhone?: boolean;
  isSmallScreen?: boolean;
  isTablet?: boolean;
};

export const MediaQueryContext = React.createContext<MediaQueryContextType>({});

export const MediaQueryContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const isPhone = useMediaQuery('(min-width: 0px) and (max-width: 480px)');
  const isTablet = useMediaQuery('(min-width: 481px) and (max-width: 768px)');
  const isSmallScreen = useMediaQuery('(min-width: 769px) and (max-width: 1024px)');
  const isLargeScreen = useMediaQuery('(min-width: 1025px )');
  const isLandscape = useMediaQuery('(max-width: 768px) and (orientation: landscape)');

  return (
    <MediaQueryContext.Provider
      value={{
        isLandscape,
        isLargeScreen,
        isPhone,
        isSmallScreen,
        isTablet,
      }}
    >
      {children}
    </MediaQueryContext.Provider>
  );
};
