import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import cn from 'classnames';

import { ReactComponent as ArrowDown } from '../assets/icons/down-arrow.svg';
import { ImageBanner, Section } from '../components';
import { Page } from '../layout';
import { routes } from '../routes';

import styles from './QuiSuisJe.images.module.css';

export const QuiSuisJe = () => {
  const [collapse, setCollapse] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (prevPos.y > 0 && currPos.y === 0) setCollapse(false);
      if (prevPos.y === 0 && currPos.y > 0) setCollapse(true);
    },
    [],
    undefined,
    true,
  );

  return (
    <Page>
      <div className={cn(styles.arrow_container, { [styles.visible]: !collapse })}>
        <ArrowDown className={cn(styles.arrow_down, { [styles.visible]: !collapse })} />
      </div>
      <ImageBanner className={styles.top_image} title="Qui suis-je ?" verticalAlign="bottom" />
      <Section>
        <p>
          Je m&apos;appelle Agathe, je suis originaire de la Loire-Atlantique. J&apos;ai toujours
          été passionnée par ces animaux majestueux que sont les chevaux, et j&apos;ai fait mes
          premiers apprentissages en centre équestre lorsque j&apos;étais encore au collège.
        </p>
        <p>
          J&apos;ai toujours su que je voulais travailler avec les chevaux, mon bac scientifique en
          poche je suis donc partie faire mes armes sur le terrain. J&apos;ai appris à manier la
          fourche, manipuler toutes sortes de chevaux, appris à faire les soins de base, et bien sûr
          j&apos;ai énormément progressé à cheval. Pendant plusieurs années, j&apos;ai ainsi acquis
          les bases du travail avec les chevaux, avec toujours cependant la volonté d&apos;aller
          plus loin dans le respect du cheval.
        </p>
        <p>
          C&apos;est pendant ces années, à croiser bien plus de chevaux que je n&apos;aurais pu
          imaginé, que je rencontre Ce Bai, mon compagnon à 4 pattes depuis 2019. Son arrivée dans
          ma vie me fait remettre beaucoup de choses en question dans ma vision du cheval et ma
          pratique.
        </p>
        <p>
          En octobre 2020, j&apos;ai donc attaqué la formation{' '}
          <a href="https://equitalliance.fr" target="_blank" rel="noreferrer">
            Equitalliance
          </a>{' '}
          qui m&apos;a apporté énormément, tant sur le plan professionnel que personnel. Plusieurs
          années après, il m&apos;arrive encore de découvrir mes évolutions personnelles et tous les
          outils que j&apos;ai appris à utiliser pendant cette formation me servent chaque jour. Je
          suis fière du chemin parcouru et je ne regrette absolument d&apos;avoir pris un virage
          radical dans ma pratique équestre.
        </p>
        <p>
          Mon activité de comportementaliste équin est pour moi l&apos;accomplissement de beaucoup
          d&apos;années de travail, d&apos;investissement et d&apos;énormément de remises en
          question. J&apos;espère sincèrement pouvoir aider au mieux les personnes que
          j&apos;accompagne vers plus de bien-être, de sérénité et de confiance dans leur rapport
          aux chevaux et à eux-mêmes.
        </p>
      </Section>
      <ImageBanner
        className={styles.bottom_image}
        title="Comportementaliste équin, qu’est-ce que ça signifie ?"
      />
      <Section>
        <p>
          Le comportementaliste équin, comme son nom l&apos;indique, est capable d&apos;évaluer le
          comportement de votre cheval, d&apos;identifier ses blocages, et d&apos;analyser la
          relation que vous entretenez avec lui. Il n&apos;est en aucun cas là pour vous juger, mais
          pour vous proposez des solutions afin de régler une problématique à pied/à cheval, ou
          simplement pour faire évoluer votre relation.
        </p>
        <p>
          Pour ce faire, il va d&apos;abord vérifier l&apos;environnement de votre cheval (mode de
          vie, entourage équin et humain, besoins fondamentaux…) puis établir un programme de
          travail pour répondre au mieux à votre problématique. Il accompagne autant le cheval que
          le cavalier à dépasser les blocages présents.
        </p>
        <p>
          Le comportementaliste se doit d&apos;avoir des connaissances solides en éthologie
          scientifique, théories d&apos;apprentissages chez le cheval, fonctionnement physique ou
          mental, et dans bien d&apos;autres domaines.
        </p>
        <p>
          Certifiée Comportementaliste équin par la méthode{' '}
          <a href="https://equitalliance.fr" target="_blank" rel="noreferrer">
            Equitalliance
          </a>
          , mes principaux outils sont les accompagnements énergétiques et le clicker-training. Vous
          trouverez quelques explications dans l&apos;onglet{' '}
          <Link to={`../${routes.prestations.pathname}`}>Prestations</Link>.
        </p>
      </Section>
    </Page>
  );
};
