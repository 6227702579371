import React, { useState } from 'react';
import cn from 'classnames';

import styles from './PriceCard.module.css';

interface NewPriceCardProps {
  cases?: string[];
  content?: string[];
  extra?: React.ReactNode;
  description?: string;
  forWho?: React.ReactNode;
  imageClassName?: string;
  price: React.ReactNode;
  title: string;
}

let globalId = 0;

export const PriceCard = ({
  cases,
  content,
  description,
  forWho,
  imageClassName,
  price,
  title,
}: NewPriceCardProps) => {
  const [open, setOpen] = useState(false);

  const titleId = `price-information-${globalId++}`;

  return (
    <div aria-labelledby={titleId} className={styles.card}>
      <details open={open}>
        <summary className={styles.summary} onClick={(e) => e.preventDefault()}>
          <h2 id={titleId}>{title}</h2>
          <div className={styles.price}>{price}</div>
          <p>{description}</p>
          {forWho}
          {content && (
            <button onClick={() => setOpen((prev) => !prev)}>
              {open ? 'Moins...' : "Plus d'informations..."}
            </button>
          )}
        </summary>
        <div className={styles.content}>
          <div>
            <h3>Contient :</h3>
            <ul>
              {content?.map((element, index) => (
                <li key={index}>{element}</li>
              ))}
            </ul>
          </div>
          <div>
            <h3>Dans quels cas ?</h3>
            <ul>
              {cases?.map((element, index) => (
                <li key={index}>{element}</li>
              ))}
            </ul>
          </div>
        </div>
      </details>
      <div className={cn(styles.image, imageClassName)} />
    </div>
  );
};
