import React from 'react';

import horseSilhouette from '../assets/images/misc/horse-silhouette.png';
import humanSilhouette from '../assets/images/misc/man-silhouette.png';
import { ImageBanner, PriceCard } from '../components';
import { Page } from '../layout';

import styles from './Tarifs.module.css';
import imagesStyles from './Tarifs.images.module.css';

export const Tarifs = () => (
  <Page className={styles.page}>
    <ImageBanner
      className={imagesStyles.top_image}
      title="Accompagnements Énergétiques"
      verticalAlign="bottom"
    />
    <div className={styles.grid}>
      <PriceCard
        cases={[
          'Pour une chute ou blessure sans gravité (aider à la résorption des bleus, œdèmes, guérison des plaies, etc.)',
          'Maladies du quotidien (rhume, Covid, grippe, gastroentérite, etc.)',
          'Réactions allergiques',
          'Fatigue passagère',
          'Ou simplement pour se sentir mieux',
        ]}
        content={['1 Lecture du Vivant', '1 RDV téléphonique pour discuter du compte-rendu']}
        description="Pour faire face aux petits traumas ou simplement pour se sentir bien"
        forWho={
          <div className={styles.for_who}>
            <p>Pour humains & animaux</p>
            <div className={styles.images}>
              <img src={humanSilhouette} alt="Pour humains" />
              <img src={horseSilhouette} alt="Pour animaux" />
            </div>
          </div>
        }
        title="Formule simple"
        price="35 €"
      />
      <PriceCard
        cases={[
          'Pour aider pendant une convalescence, suite à une blessure importante (entorses, fractures…)',
          'Pendant et/ou suite à une maladie',
          'Réactions allergiques',
          'Fatigue mentale et/ou physique',
          'Dépression',
          'Ou simplement pour se sentir mieux',
        ]}
        content={[
          '1 Lecture du Vivant',
          '1 RDV téléphonique pour discuter du compte-rendu',
          '1 retour demandé',
          '1 nouvelle Lecture du Vivant',
        ]}
        description="Pour faire face aux traumas plus importants ou simplement pour se sentir bien"
        forWho={
          <div className={styles.for_who}>
            <p>Pour humains & animaux</p>
            <div className={styles.images}>
              <img src={humanSilhouette} alt="Pour humains" />
              <img src={horseSilhouette} alt="Pour animaux" />
            </div>
          </div>
        }
        price={
          <>
            <p>80 € pour le premier suivi</p>
            <p>30 € pour chaque Lecture suivante</p>
          </>
        }
        title="Formule complète"
      />
      <PriceCard
        cases={[
          'Pour préparer une échéance (examen, compétition sportive…)',
          'Pour faire face à une problématique émotionnelle récurrente légère',
          'Pour aider lors de périodes stressantes et/ou fatigantes',
          'Ou simplement pour se sentir mieux',
        ]}
        content={['1 séance téléphonique ou en présentiel (+ frais de déplacements)']}
        forWho={
          <div className={styles.for_who}>
            <p>Pour humains, adaptable pour les animaux</p>
            <div className={styles.images}>
              <img src={humanSilhouette} alt="Pour humains" />
              <img src={horseSilhouette} alt="Pour animaux" />
            </div>
          </div>
        }
        price="40 €"
        title="Accompagnement émotionnel"
      />
    </div>
    <ImageBanner className={imagesStyles.bottom_image} title="Accompagnements auprès des chevaux" />
    <div className={styles.grid}>
      <PriceCard
        cases={[
          'Blocages',
          'Peurs',
          'Rétivité',
          'Problèmes d’embarquement',
          'Problèmes de sociabilité',
          'Agressivité',
        ]}
        content={[
          'Création/renforcement du lien cheval/cavalier sur 1 séance minimum (à voir selon chaque cas)',
          'Gestion des émotions du binôme',
          'Accompagnement énergétique au besoin',
          'Gestion de l’environnement',
          'Exercices spécifiques au clicker pour travailler sur la problématique du couple',
        ]}
        title="Gestion de comportements déviants"
        price={
          <>
            <p>150 € les 2 premières séances</p>
            <p>60 € les séances suivantes</p>
          </>
        }
      />
      <PriceCard
        cases={[
          'Pour retrouver du plaisir dans le travail avec le cheval',
          'Pour affiner la communication du binôme',
          'Pour améliorer la qualité de la relation',
          'Pour découvrir de nouvelles façons d’interagir, intégrer de nouveaux éléments dans l’équitation classique',
        ]}
        content={[
          'Création/renforcement du lien cheval/cavalier sur 1 séance minimum (à voir selon chaque cas)',
          'Initiation aux connexions énergétiques',
          'Initiation au clicker-training',
          'Gestion des émotions',
        ]}
        extra={
          <>
            <p>Formule personnalisable selon le couple et les objectifs</p>
            <p>
              <strong>
                Forfaits possibles pour les deux types d’accompagnements : 330 € les 5 séances, 600€
                les 10 séances.
              </strong>
            </p>
            <p className={styles.warning}>
              !! N’étant pas titulaire du BPJEPS, je n’apporte aucun conseil technique équestre.
              Nécessité d’être autonome. !!
            </p>
          </>
        }
        title="Accompagnement relationnel"
        price={
          <>
            <p>150 € les 2 premières séances</p>
            <p>60 € les séances suivantes</p>
          </>
        }
      />
      <PriceCard
        title="Initiation au clicker-training"
        price="60 € la séance, individuelle ou collective"
      />
    </div>
    <p className={styles.warning}>
      <strong>
        <em>Les tarifs présentés ci-dessus ne comprennent pas les frais de déplacements.</em>
      </strong>
    </p>
  </Page>
);
