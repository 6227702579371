import React, { HTMLProps, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

import styles from './Page.module.css';

export const Page = ({ children, className, ...props }: HTMLProps<HTMLDivElement>) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <main className={cn(styles.page, className)} {...props}>
      {children}
    </main>
  );
};
