import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import cn from 'classnames';

import logo from '../assets/logo/logo-simple-150.png';
import smallScreenLogo from '../assets/logo/logo-ultra-simple-150.png';
import { MediaQueryContext } from '../contexts';
import { routes } from '../routes';

import styles from './Header.module.css';

const Nav = ({
  active,
  contrast,
  onClose,
}: {
  active?: boolean;
  contrast?: boolean;
  onClose?: () => void;
}) => (
  <nav className={cn(styles.nav, { [styles.active]: active, [styles.contrast]: contrast })}>
    <ul className={styles.items}>
      {Object.entries(routes).map(([, { key, label, pathname }]) => (
        <NavLink
          key={key}
          to={pathname}
          className={({ isActive }) =>
            cn(styles.link, {
              [styles.selected]: isActive,
            })
          }
          onClick={onClose}
        >
          <li className={cn(styles.item)}>{label}</li>
        </NavLink>
      ))}
    </ul>
  </nav>
);

export const Header = () => {
  const { pathname } = useLocation();

  const [collapse, setCollapse] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (prevPos.y > 0 && currPos.y === 0) setCollapse(false);
      if (prevPos.y === 0 && currPos.y > 0) setCollapse(true);
    },
    [],
    undefined,
    true,
  );

  return (
    <MediaQueryContext.Consumer>
      {({ isPhone, isTablet }) => (
        <>
          <header className={cn(styles.header, { [styles.collapse]: collapse })}>
            <Link className={styles.logo} to={routes.home}>
              <img
                alt="Equipage, il est temps de faire équipe"
                src={isPhone || isTablet ? smallScreenLogo : logo}
              />
            </Link>
            {!isPhone && !isTablet && (
              <Nav
                contrast={
                  pathname.endsWith(routes.tarifs.pathname) ||
                  pathname.endsWith(routes.contact.pathname)
                }
              />
            )}
            {(isPhone || isTablet) && (
              <div className={styles.hamburger_wrapper}>
                <div
                  className={cn(styles.hamburger, { [styles.active]: openMenu })}
                  role="button"
                  onClick={() => setOpenMenu((prev) => !prev)}
                >
                  <span className={styles.line} />
                  <span className={styles.line} />
                  <span className={styles.line} />
                  <span className={styles.line} />
                  <span className={styles.line} />
                </div>
              </div>
            )}
          </header>
          {(isPhone || isTablet) && <Nav active={openMenu} onClose={() => setOpenMenu(false)} />}
        </>
      )}
    </MediaQueryContext.Consumer>
  );
};
