import React from 'react';
import { Link } from 'react-router-dom';

import { ImageBanner, Section } from '../components';
import { Page } from '../layout';
import { routes } from '../routes';

import styles from './Prestations.images.module.css';

export const Prestations = () => (
  <Page className={styles.page}>
    <ImageBanner
      className={styles.top_image}
      title="Accompagnements énergétiques*, pour qui, pour quoi ?"
      verticalAlign="bottom"
    />
    <Section>
      <p>
        Les accompagnements énergétiques* sont vraiment complémentaires des médecines
        traditionnelles (tout comme le shiatsu par exemple, ou la kinésiologie qui tendent à se
        démocratiser).
      </p>
      <p>
        Le but de la Lecture du Vivant* est d&apos;aider le corps, ses organes et ses systèmes à
        retrouver sa capacité d&apos;auto-guérison. Cela n&apos;a rien de mystique, en fait, on
        utilise le bioélectromagnétisme présent dans tous les êtres vivants. C&apos;est pour ça que
        ces accompagnements* s&apos;appliquent à toutes les espèces vivantes : humains, animaux et
        même végétaux !
      </p>
      <p>
        Dans quels cas demander un accompagnement énergétique* ? Pendant une maladie pour guérir
        plus vite (en boostant les différents systèmes touchés), ou sur une blessure pour les mêmes
        raisons. En cas de grosses émotions (stress, peur, colère, tristesse…), une Lecture du
        Vivant* permet d&apos;apaiser l&apos;esprit et les maux qui sont liés. Pour les animaux,
        elle peut être utile pour apaiser et rééquilibrer les liens au sein d&apos;un troupeau ou
        entre 2 individus. La Lecture du Vivant* permet également de travailler sur les mémoires
        associées à des lieux ou des évènements passés. Et puis parfois, l&apos;accompagnement
        énergétique* est juste là pour apporter du confort, du bien-être et de la sérénité.
      </p>
      <p>
        Il est parfois nécessaire de répéter les Lectures du Vivant* plusieurs fois, mais pour
        d&apos;autres cas, les bienfaits sont visibles immédiatement !
      </p>
      <p className={styles.warning}>
        Alors n&apos;hésitez plus à intégrer l&apos;accompagnement énergétique* dans votre routine
        ou celle de vos animaux ! Vous retrouverez toutes les formules disponibles dans
        l&apos;onglet <Link to={`../${routes.tarifs.pathname}`}>Tarifs</Link>.
      </p>
      <p className={styles.source}>
        *Selon les méthodes de Gilles Goncalves - Institut de Médecine Traditionnelle Chinoise et
        Vietnamienne Hoa Thien Duong.
      </p>
    </Section>
    <ImageBanner
      className={styles.middle_image}
      title="Les accompagnements auprès des chevaux, comment ça marche ?"
    />
    <Section>
      <p>
        Parce qu&apos;à mon sens, une des clés les plus importantes pour avancer sereinement auprès
        de votre cheval, après sa santé et la vôtre, est tout simplement la relation que vous
        entretenez avec lui. C&apos;est pourquoi je souhaite la mettre au cœur des mes
        accompagnements.
      </p>
      <p>
        Travailler sur celle-ci engendre des changements spectaculaires mais aussi durables car
        profonds. Les chevaux sont de véritables professeurs et nous emmènent patiemment nous
        explorer nous-mêmes.
      </p>
      <p>
        Les deux formules que je vous propose possèdent une base commune : deux premières séances
        minimum, dont une (ou plus si besoin) pour créer/retrouver du lien avec votre cheval, dans
        une bonne émotion. Ensuite, selon vos objectifs (résolution de problématique ou non), nous
        continuons les séances à votre rythme et celui de votre cheval. Je prends en compte la
        personnalité de chacun, humains ou animaux, c&apos;est pourquoi nous déciderons ensemble de
        la fréquence des séances afin de suivre un rythme qui vous convient et qui respecte
        l&apos;intégrité physique et mentale de votre binôme.
      </p>
      <p>
        Clicker-training, gestion de l&apos;environnement, gestion des émotions, accompagnements
        énergétiques et/ou émotionnels, initiation aux connexions énergétiques et bien
        d&apos;autres, mes outils sont nombreux et adaptables à votre situation.
      </p>
      <p>
        Ces accompagnements s&apos;adressent donc aux gardiens qui rencontrent des blocages avec
        leurs chevaux, mais aussi à ceux qui souhaitent simplement faire évoluer leur relation avec
        leur équidé ou y intégrer de nouveaux outils pour plus de sérénité et de plaisir.
      </p>
      <p>
        Retrouvez les formules que je vous propose dans l&apos;onglet{' '}
        <Link to={`../${routes.tarifs.pathname}`}>Tarifs</Link>.
      </p>
    </Section>
    <ImageBanner className={styles.bottom_image} title="Les initiations au clicker-training" />
    <Section>
      <p>
        Prenez le temps de découvrir l&apos;entraînement au clicker avec vos chevaux. Grâce à cet
        outil, vous pourrez dépenser votre cheval mentalement, l&apos;inciter à réfléchir et à
        devenir plus autonome, mais aussi et surtout amener du plaisir dans le travail !
      </p>
      <p>
        Parfait pour favoriser les bonnes émotions, et amener de la motivation et de la joie dans le
        travail, le clicker-training est un excellent moyen de renforcer votre lien et votre
        connexion avec votre cheval.
      </p>
      <p>
        Ces temps d&apos;initiation peuvent être organisés en séances individuelles ou en groupe
        sous forme de stages. Retrouvez les tarifs dans l&apos;onglet{' '}
        <Link to={`../${routes.tarifs.pathname}`}>Tarifs</Link>.
      </p>
    </Section>
  </Page>
);
