import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { MediaQueryContextProvider } from './contexts';
import { Layout } from './layout';
import { Contact, Prestations, QuiSuisJe, Tarifs } from './pages';
import { routes } from './routes';

const App = () => (
  <BrowserRouter>
    <MediaQueryContextProvider>
      <Routes>
        {/* <Route index element={<AvailableSoon />} /> */}
        <Route element={<Layout />}>
          <Route path={routes.home.pathname} element={<QuiSuisJe />} />
          <Route path={routes.prestations.pathname} element={<Prestations />} />
          <Route path={routes.tarifs.pathname} element={<Tarifs />} />
          <Route path={routes.contact.pathname} element={<Contact />} />
          <Route path="*" element={<Navigate to={routes.home.pathname} />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </MediaQueryContextProvider>
  </BrowserRouter>
);

export default App;
