import React from 'react';
import { Link } from 'react-router-dom';

import { routes } from '../routes';
import { SocialMedias } from './SocialMedias';

import styles from './Footer.module.css';

export const Footer = () => (
  <footer className={styles.footer}>
    <SocialMedias className={styles.social_medias} />
    <nav className={styles.footer_nav}>
      <ul className={styles.items}>
        {Object.entries(routes).map(([, { key, label, pathname }]) => (
          <Link key={key} to={pathname} className={styles.link}>
            <li className={styles.item}>{label}</li>
          </Link>
        ))}
      </ul>
    </nav>
  </footer>
);
