import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import cn from 'classnames';

import { ReactComponent as MailIcon } from '../assets/icons/mail.svg';
import { ReactComponent as MapIcon } from '../assets/icons/map.svg';
import { ReactComponent as PhoneIcon } from '../assets/icons/phone.svg';
import { FlexContainer, ImageBanner, Section, SocialMedias } from '../components';
import { Page } from '../layout';

import styles from './Contact.module.css';
import imagesStyles from './Contact.images.module.css';

const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface FormData {
  email: string;
  message: string;
}

const encode = (data: { [key: string]: string }) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export const Contact = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { formState, handleSubmit, register, trigger } = useForm<FormData>({ mode: 'onBlur' });

  const { isValid, errors } = formState;

  const onSubmit: SubmitHandler<FormData> = (data, e) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contactForm', ...data }),
    })
      .then(() => setShowSuccessMessage(true))
      .catch(() => setShowErrorMessage(true));
    e?.preventDefault();
  };

  return (
    <Page className={styles.page}>
      <ImageBanner className={imagesStyles.top_image} title="Contact" verticalAlign="bottom" />
      <Section className={styles.section} title="Ecrivez-moi">
        <form
          className={styles.form}
          name="contactForm"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit(onSubmit)}
          hidden={showSuccessMessage}
        >
          <div
            className={cn(styles.success_message, { [styles.active]: showSuccessMessage })}
            hidden={!showSuccessMessage}
          >
            <p>Votre message a bien été envoyé. Je vous répondrai dès que possible!</p>
          </div>
          <div
            className={cn(styles.error_message, { [styles.active]: showErrorMessage })}
            hidden={!showErrorMessage}
          >
            <p>
              Oups, une erreur est survenue, votre message n&apos;a pas pu être envoyé. Essayez
              ultérieurement ou bien contactez-moi directement par mail à l&apos;adresse{' '}
              <a href="mail:ap.ce@free.fr">ap.ce@free.fr</a>.
            </p>
          </div>
          <input type="hidden" name="form-name" value="contactForm" />
          <FlexContainer direction="column" rowGap={8}>
            <label htmlFor="email">Votre adresse Email</label>
            <input
              {...register('email', {
                required: 'Merci de renseigner votre adresse email',
                pattern: {
                  value: EMAIL_REGEXP,
                  message: 'Merci de renseigner une adresse email valide',
                },
              })}
              className={styles.input}
            />
            {errors && errors.email && <p className={styles.form_error}>{errors.email.message}</p>}
          </FlexContainer>
          <FlexContainer direction="column" rowGap={8}>
            <label htmlFor="message">Message</label>
            <textarea
              {...register('message', {
                required: 'Merci de renseigner votre message',
                onChange: () => trigger(),
              })}
              className={styles.input}
            />
            {errors && errors.message && (
              <p className={styles.form_error}>{errors.message.message}</p>
            )}
          </FlexContainer>
          <button
            className={styles.button}
            type="submit"
            disabled={!isValid || showSuccessMessage || showErrorMessage}
          >
            Envoyer
          </button>
        </form>
      </Section>
      <Section className={styles.section}>
        <FlexContainer className={styles.info} direction="column" rowGap={24} stretch={false}>
          <p className={styles.name}>Agathe Pasquereau</p>
          <FlexContainer columnGap={42} className={styles.basics}>
            <FlexContainer>
              <PhoneIcon />
              <a href="tel:+33786960813">
                <strong>07 86 96 08 13</strong>
              </a>
            </FlexContainer>
            <FlexContainer>
              <MailIcon />
              <a href="mail:ap.ce@free.fr">
                <strong>ap.ce@free.fr</strong>
              </a>
            </FlexContainer>
            <FlexContainer>
              <MapIcon />
              <p>
                <strong>Vallet, Loire-Atlantique</strong>
              </p>
            </FlexContainer>
          </FlexContainer>
          <SocialMedias className={styles.social_medias} />
        </FlexContainer>
      </Section>
    </Page>
  );
};
