import React, { HTMLProps } from 'react';
import cn from 'classnames';

import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../assets/icons/instagram.svg';
import { FlexContainer } from './FlexContainer';

import styles from './SocialMedias.module.css';

export const SocialMedias = ({ className, ...props }: HTMLProps<HTMLDivElement>) => (
  <FlexContainer
    className={cn(styles.social_medias, className)}
    direction="column"
    stretch={false}
    {...props}
  >
    <span>Retrouvez Equipage sur les réseaux sociaux</span>
    <FlexContainer>
      <a
        href="https://www.facebook.com/equipage.comportementalisteequin"
        target="_blank"
        rel="noreferrer"
      >
        <FacebookIcon width={42} height={42} />
      </a>
      <a
        href="https://www.instagram.com/equipage.comportementaliste/"
        target="_blank"
        rel="noreferrer"
      >
        <InstagramIcon width={42} height={42} />
      </a>
    </FlexContainer>
  </FlexContainer>
);
