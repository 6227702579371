import React, { HTMLProps } from 'react';
import cn from 'classnames';

import styles from './Section.module.css';

interface SectionProps extends HTMLProps<HTMLDivElement> {
  title?: string;
}

export const Section = ({ children, className, title, ...props }: SectionProps) => (
  <section className={cn(styles.section, className)} {...props}>
    {title && <h1>{title}</h1>}
    {children}
  </section>
);
